import React, { useState } from "react";
import { Row, Col, Button, ButtonGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import {
  Q1,
  Q2,
  Q3,
  Q4,
  Q5,
  Q6,
  Q7,
  Q8,
  Q9,
  Q10,
  Q11,
  Q12,
  Q13,
  Q14,
  Q15,
  Q16,
  Q17,
  Q18,
  Q19,
  Q20,
  Q21,
  Q22,
  Q23,
} from "./Filters/HouseFilter";

const Queening = () => {
  const [filterBe, setFilterBe] = useState(false);
  const [filterBo, setFilterBo] = useState(false);
  const [filterEr, setFilterEr] = useState(false);
  const [filterEs, setFilterEs] = useState(false);
  const [filterFo, setFilterFo] = useState(false);
  const [filterGr, setFilterGr] = useState(false);
  const [filterHe, setFilterHe] = useState(false);
  const [filterHg, setFilterHg] = useState(false);
  const [filterKu, setFilterKu] = useState(false);
  const [filterMe, setFilterMe] = useState(false);
  const [filterMu, setFilterMu] = useState(false);
  const [filterNa, setFilterNa] = useState(false);
  const [filterOl, setFilterOl] = useState(false);
  const [filterOt, setFilterOt] = useState(false);
  const [filterOx, setFilterOx] = useState(false);
  const [filterPf, setFilterPf] = useState(false);
  const [filterPn, setFilterPn] = useState(false);
  const [filterVa, setFilterVa] = useState(false);
  const [filterSt, setFilterSt] = useState(false);
  const [filterSv, setFilterSv] = useState(false);
  const [filterTo, setFilterTo] = useState(false);
  const [filterTr, setFilterTr] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    setFilterBe("");
    setFilterBo("");
    setFilterEr("");
    setFilterEs("");
    setFilterFo("");
    setFilterGr("");
    setFilterHe("");
    setFilterHg("");
    setFilterKu("");
    setFilterMe("");
    setFilterMu("");
    setFilterNa("");
    setFilterOl("");
    setFilterOt("");
    setFilterOx("");
    setFilterPf("");
    setFilterPn("");
    setFilterVa("");
    setFilterSt("");
    setFilterSv("");
    setFilterTo("");
    setFilterTr("");
  };

  //window.location.reload();

  return (
    <>
      <Row>
        <Col>
          <Button variant="secondary">
            {" "}
            <Link
              to="/queenking_search"
              style={{ textDecoration: "none", color: "white" }}
            >
              <CiSearch /> Drottningar & Kungar
            </Link>{" "}
          </Button>
        </Col>
      </Row>
      <br></br>

      <Row>
        {" "}
        <Col sm={2} lg={2} md={2} xl={2} align="center">
          {" "}
          <Row>
            <br></br>
            <br></br>
            <Col align="left" style={{ color: "white" }}>
              <h3>Ätter</h3>
            </Col>
          </Row>
          <Col align="left">
            <Form onSubmit={submitHandler}>
              <ButtonGroup vertical>
                <Button type="submit" variant="danger">
                  Rensa
                </Button>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterMu"
                    onClick={(e) => setFilterMu(!filterMu)}
                  >
                    Munsö
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterSt"
                    onClick={(e) => setFilterSt(!filterSt)}
                  >
                    Stenskil
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterEr"
                    onClick={(e) => setFilterEr(!filterEr)}
                  >
                    Erikska
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterFo"
                    onClick={(e) => setFilterFo(!filterFo)}
                  >
                    Folkunga
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterMe"
                    onClick={(e) => setFilterMe(!filterMe)}
                  >
                    Mecklenburg
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterKu"
                    onClick={(e) => setFilterKu(!filterKu)}
                  >
                    Kalmar Unionen
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterGr"
                    onClick={(e) => setFilterGr(!filterGr)}
                  >
                    Grip
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterBo"
                    onClick={(e) => setFilterBo(!filterBo)}
                  >
                    Bonde
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterPn"
                    onClick={(e) => setFilterPn(!filterPn)}
                  >
                    Pfalz-Neumarkt
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterSv"
                    onClick={(e) => setFilterSv(!filterSv)}
                  >
                    Sverkerska
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterSv"
                    onClick={(e) => setFilterOx(!filterOx)}
                  >
                    Oxenstierna
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterTo"
                    onClick={(e) => setFilterTo(!filterTo)}
                  >
                    Tott
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterEs"
                    onClick={(e) => setFilterEs(!filterEs)}
                  >
                    Estridsen
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterOl"
                    onClick={(e) => setFilterOl(!filterOl)}
                  >
                    Oldenburg
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterNa"
                    onClick={(e) => setFilterNa(!filterNa)}
                  >
                    Natt och Dag
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterTr"
                    onClick={(e) => setFilterTr(!filterTr)}
                  >
                    Trolle
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterVa"
                    onClick={(e) => setFilterVa(!filterVa)}
                  >
                    Vasa
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterPf"
                    onClick={(e) => setFilterPf(!filterPf)}
                  >
                    Pfalziska
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterHe"
                    onClick={(e) => setFilterHe(!filterHe)}
                  >
                    Hessiska
                  </Button>
                </a>
                &nbsp;{" "}
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterHg"
                    onClick={(e) => setFilterHg(!filterHg)}
                  >
                    Holstein-Gottorp
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterSa"
                    onClick={(e) => setFilterBe(!filterBe)}
                  >
                    Bernadotte
                  </Button>
                </a>
                &nbsp;
                <a href="#main" style={{ color: "white" }}>
                  <Button
                    style={{ width: 150 }}
                    value="filterOt"
                    onClick={(e) => setFilterOt(!filterOt)}
                  >
                    Other
                  </Button>
                </a>
                &nbsp;
                <Button type="submit" variant="danger">
                  Rensa
                </Button>
              </ButtonGroup>
            </Form>
          </Col>
        </Col>
        <Col sm={10} lg={10} md={10} xl={10}>
          {filterBe ? (
            <Q2 />
          ) : filterVa ? (
            <Q3 />
          ) : filterMu ? (
            <Q4 />
          ) : filterSt ? (
            <Q5 />
          ) : filterEr ? (
            <Q6 />
          ) : filterSv ? (
            <Q7 />
          ) : filterFo ? (
            <Q8 />
          ) : filterPf ? (
            <Q9 />
          ) : filterOl ? (
            <Q10 />
          ) : filterMe ? (
            <Q11 />
          ) : filterBo ? (
            <Q12 />
          ) : filterHe ? (
            <Q13 />
          ) : filterHg ? (
            <Q14 />
          ) : filterKu ? (
            <Q15 />
          ) : filterEs ? (
            <Q16 />
          ) : filterGr ? (
            <Q17 />
          ) : filterPn ? (
            <Q18 />
          ) : filterOx ? (
            <Q19 />
          ) : filterOt ? (
            <Q20 />
          ) : filterNa ? (
            <Q21 />
          ) : filterTr ? (
            <Q22 />
          ) : filterTo ? (
            <Q23 />
          ) : (
            <Q1 />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Queening;
