import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import { FaCrown } from "react-icons/fa";

const Modalia = () => {
  const [show, setShow] = useState(false);

  console.log(show);
  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    window.localStorage.setItem("show", Date.now(), JSON.stringify(show));

    JSON.parse(window.localStorage.getItem("show"));
  });

  useEffect(() => {
    if (!localStorage.getItem("show", Date.now())) setShow(false);
    localStorage.setItem("show", Date.now(), true);
  }, []);

  return (
    <>
      <Button variant="primary" size="sm" value={show} onClick={handleShow}>
        ?
      </Button>

      {show ? (
        <Col align="center">
          <Card bg="blue">
            <Row>
              <Col
                style={{
                  color: "white",
                  marginLeft: 5,
                  marginRight: 5,
                  backgroundColor: "black",
                }}
              >
                <br></br> <h3>Explanations</h3>
                <br></br>
                <p>
                  [c] is short for circa or approximately <br></br>
                  <br></br>
                  [A.D] means "In the year of the lord and refers to his or hers
                  birth date"
                  <br></br> <br></br>
                  [ <FaCrown />] &nbsp; refers to the time on throne
                  <br></br> <br></br>Last revised on Jan 4th 2025
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default Modalia;
