import React, { useState } from "react";
import { Row, Col, InputGroup, Form, Button, Card } from "react-bootstrap";
import queen from "../queens";
import { Link, useNavigate } from "react-router-dom";
import konungalength from "../components/images/konungalength.jpg";
import { IoReturnUpBack } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import Product from "../components/Product";
import Modalia from "../components/Modalia";

const QueenKingSearch = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  function handleChange(e) {
    setSearch(e.target.value);
  }

  function addDefaultSrc(ev) {
    ev.target.src = konungalength;
  }

  return (
    <>
      <div>
        {" "}
        <Col>
          {" "}
          <Button variant="primary" size="sm" onClick={() => navigate(-1)}>
            <IoReturnUpBack />{" "}
          </Button>{" "}
          <Modalia />{" "}
        </Col>
        <br></br> <br></br>
        <Col>
          <h2 style={{ color: "white" }} align="center">
            {" "}
            Drottningar & Kungar
          </h2>{" "}
        </Col>
      </div>

      <Row>
        <Col>
          <Button variant="secondary">
            {" "}
            <Link
              to="/queenking_filter"
              style={{ textDecoration: "none", color: "white" }}
            >
              <CiFilter /> Drottningar & Kungar
            </Link>
          </Button>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col md={4} xl={4} sm={4} style={{ marginBottom: 10 }}>
          <InputGroup className="mb-2" bg="black" style={{ color: "red" }}>
            <Form.Control
              type="text"
              placeholder="Sök Monarker"
              value={
                search.charAt().toUpperCase() + search.slice(1).toLowerCase(1)
              }
              onChange={handleChange}
              style={{ color: "black", bg: "black" }}
            />
          </InputGroup>{" "}
        </Col>
      </Row>
      <Row>
        <Row>
          <Col>
            <h2> Svenska Monarker ({queen.length})</h2>
          </Col>
        </Row>
        <br></br>
        <Row>
          {queen
            .filter((item) => item.name.includes(search))
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
          <br></br>
        </Row>{" "}
        <br></br>
      </Row>
    </>
  );
};

export default QueenKingSearch;
