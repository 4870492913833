import React from "react";
import { Col, Button } from "react-bootstrap";
import ScrollToTop from "react-scroll-to-top";
import QueenComponentFilter from "../components/QueenComponentFilter";
import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Modalia from "../components/Modalia";

const QueenKingFilter = (queen) => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        {" "}
        <Col>
          {" "}
          <Button variant="primary" size="sm" onClick={() => navigate(-1)}>
            <IoReturnUpBack />{" "}
          </Button>{" "}
          <Modalia />{" "}
        </Col>
        <br></br>
        <br></br>
        <Col>
          <h2 style={{ color: "white" }} align="center">
            {" "}
            Drottningar & Kungar{" "}
          </h2>{" "}
        </Col>
      </div>
      <QueenComponentFilter />

      <ScrollToTop />
    </>
  );
};

export default QueenKingFilter;
